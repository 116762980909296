<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/muhammadsharif-sufizoda.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Муҳаммадшариф Сўфизода<br />(1880–1937)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Муҳаммадшариф Сўфизода Маърифатпарвар зиёли, шоир Муҳаммадшариф
            Сўфизода Ўзбек маданияти тарихида ёрқин из қолдирган зотлардандир.
            Муҳаммадшариф Егамберди оғли 1969-йил 29-январда Чустда туғилди,
            унинг отаси пичоқчи-ҳунарманд еди. У маҳалласидаги қўшниси Манзура
            отинда савод чиқарди. 1893–1898-йилларда Қўқонда яшади ва мадрасада
            таълим олди. Қўқон адабий муҳитининг машҳур шоирлари Муқимий, Муҳйи,
            Завқий, Нодим Намангоний билан яқин муносабатда бўлди. Муқимий
            тўгарагида фаол қатнашиши шоир дунёқарашининг шаклланишида, бадиий
            маҳоратининг ўсишида ўзига хос мактаб бўлди. Бўлажак шоир дастлабки
            шеърларига Муқимий тавсияси билан „Ваҳший“ тахаллусини қўллади.
            Сўфизода 1893-йилда она шаҳри Чустда боёнларни, чор амалдорларини,
            мутаассибларни ҳажв қилувчи шеърлари учун „бадасл“, „беадаб“,
            „даҳрий“ деб айбланади ва ўлимга ҳукм қилинади. Шоир ўз ватанини
            тарк етишга, 14 йил турли мамлакатларда истиқомат қилишга мажбур
            бўлади. Сўфизода 1900-1913-йилларда Ўрта Осиёнинг турли шаҳарларида,
            Тифлис, Боку, Aрабистон, Ҳиндистон, Туркияда бўлган. У қайерда
            яшамасин, Тошкент, Кавказ, Қрим, Оренбург, Туркияда чоп етиладиган
            газеталар билан алоқасини узмаган. Унинг мақолалари, шеърлари
            „Туркистон вилоятининг газети“, „Садойи Туркистон“, „Садойи Фарғона“
            газеталарида мунтазам чиқиб турган. 1913-йилда Сўфизода чет ел
            сафаридан Чустга қайтиб келади ва усули жадид мактабини очади.
            Камарсада қишлоғида йетим болалар учун „Дорулайтом“ („Йетимлар уйи“)
            ва катталар учун кечки мактаб ташкил қилади. Бу мактабларда у она
            тили, ҳандаса каби фанларни ўқитади. 1914-йилда Сўфизода Туркистон
            миқёсида ҳукм сураётган ижтимоий-сиёсий, маънавий таназзулни фош
            етувчи „Чустилар бизлар“ шеърини еълон қилади. Шеър бошидан
            охиригача „усули қадим“чиларга- мутаассибларга нисбатан аччиқ киноя,
            асар қаҳрамонларининг ўзини ўзи фош етиши услубида ёзилган еди. Бу
            тўқнашувдан кейин Сўфизода ўз ватани Чустдан қувғин қилинди. У
            Тўрақўрғон яқинидаги Шаҳанд қишлоғида усули жадид мактаби очди.
            Сўфизоданинг бу ишида Тўрақўрғон қозиси, маърифатпарвар, шоир,
            педагог Исъҳоқхон тўра Ибрат яқиндан ёрдам берди. Сўфизода шўролар
            ҳукумати даврида ҳам халқига чин дилдан хизмат қилаверди ва
            адабий-ижодий, педагогик фаолият билан шуғулланди. 1937-йилда шоир
            „халқ душмани“ деб йеълон қилиниб, қамоққа олинди. Сўфизода
            қабрининг қайердалиги номаълум. 1935 йилнинг 29 январида
            Сўфизоданинг туғилганига 55 йил тўлиши муносабати билан юбилей
            ўтказилган ва 200 дан ортиқ шеъри нашр етиш учун тўпланган еди.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
